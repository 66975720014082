.paragraph {
  font-size: 2rem;
}

.title {
  font-size: 3rem;
}

@media (min-width: 20rem) {
  .paragraph {
    font-size: 1.3rem;
  }
  .title {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) {
  .paragraph {
    font-size: 1.5rem;
  }
  .title {
    font-size: 2rem;
  }
}
