.heading {
  font-size: 35px;
  line-height: 72px;
  text-align: center;
}

.dash {
  position: static;
  margin: 0 auto;
  width: 100px;
  height: 4px;
  left: 59px;
  top: 76px;
}

@media screen and (max-width: 600px) {
  .heading {
    font-size: 32px;
    line-height: 72px;
    text-align: center;
  }

  .dash {
    position: static;
    margin: 0 auto;
    width: 100px;
    height: 4px;
    left: 59px;
    top: 76px;
  }
}
