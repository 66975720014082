.talkcard_root {
  width: 500px;
  height: 270px;
  cursor: pointer;
  background: white;
  filter: drop-shadow(0px 6px 64px rgba(112, 144, 176, 0.1));
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 24px;
  margin: 0 4rem 4rem 0;
  position: relative;
}

.purpleTop {
  position: absolute;
  top: 0;
  height: 8rem;
  width: 100%;
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
}

.talkLogo {
  margin-top: 8rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  height: 10rem;
  width: 10rem;
}

.talkContent {
  margin-top: 7.5rem;
}

@media only screen and (max-width: 1199px) {
  .talkcard_root {
    margin: 0;
    width: 350px;
    height: 360px;
  }
}
