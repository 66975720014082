.icon {
  width: 4rem;
  margin: 0;
}

@media screen and (max-width: 600px) {
  .icon {
    width: 3rem;
    margin: 0;
  }
}
