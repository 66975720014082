.widthWrapper {
  width: 50%;
}

.icon {
  height: 4rem;
  width: 4rem;
}

/* Larger than mobile screen */
@media (min-width: 20rem) {
  .font {
    font-size: 1rem;
  }
  .middle {
    margin: 0 auto;
    text-align: center;
  }
  .m-img {
    width: 60%;
  }
  .widthWrapper {
    margin: 0 auto;
  }
  .flex {
    display: flex;
    justify-content: center;
  }
  .company_logo {
    width: 18rem;
    height: 100%;
    object-fit: contain;
    cursor: pointer;
    padding: 1rem;
  }
  .contact_root {
    border-radius: 10px;
    padding: 3rem 0;
    height: fit-content;
  }
  .inner_contact {
    border-radius: 10px;
    padding: 2rem;
    width: 90%;
    background: white;
    margin: 5%;
  }
}

@media (min-width: 1200px) {
  .font {
    font-size: 1.5rem;
  }
  .widthWrapper {
    margin: 0;
  }
  .flex {
    display: flex;
    justify-content: flex-start;
  }
  .grid {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .company_logo {
    width: 35rem;
    height: 100%;
    object-fit: contain;
    padding: 1rem;
  }
  .contact_root {
    border-radius: 10rem;
    padding: 3rem 0;
    height: fit-content;
  }

  .inner_contact {
    background: white;
    margin: 5%;
    width: 40%;
    border-radius: 4rem;
    padding: 5rem;
  }
}

@media (min-width: 1920px) {
  .font {
    font-size: 2rem;
  }
}
