body {
  margin: 0;
  font-family: 'Days One', 'Nunito', sans-serif;
  color: #25282b;
  background: rgb(249 250 255);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.highlight {
  color: #7562df;
}

.highlight-bg {
  background: #7562df;
}

.button {
  background: #7562df !important;
  font-family: 'Nunito';
  font-weight: 800;
}

.vh {
  position: relative;
}
.vh-center {
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

/* Flex */
.flex {
  display: flex;
}

/* Margins */
.m1 {
  margin: 1rem 0;
}

.m2 {
  margin: 2rem 0;
}

.mr-2 {
  margin-right: 2rem;
}

.mb-2 {
  margin-bottom: 2rem;
}

/* Padding */

.pv-1 {
  padding: 1rem 0;
}

/* Text Sizes */
.size1 {
  font-size: 4rem;
}

.size2 {
  font-size: 2rem;
}

.size3 {
  font-size: 1rem;
}

.size4 {
  font-size: 0.8rem;
}

/* Text Formatting */
.bold {
  font-weight: bold;
}

@media (min-width: 20rem) {
  .size1 {
    font-size: 2rem;
  }
}

@media (min-width: 1200px) {
  .size1 {
    font-size: 3rem;
  }
}

@media (min-width: 1920px) {
  .size1 {
    font-size: 4rem;
  }
}

/* Responsive */
@media (min-width: 20rem) {
  .desktop {
    display: none;
  }
  .mobile {
    display: block;
  }
}

@media (min-width: 1200px) {
  .desktop {
    display: block;
  }
  .mobile {
    display: none;
  }
}

/* Scroll Bar */
body::-webkit-scrollbar {
  width: 0.6rem;
  height: 0.4rem;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 8px rgb(249 250 255);
  background-color: rgb(249 250 255);
}
body::-webkit-scrollbar-thumb {
  background-color: #7562df;
  border-radius: 8;
}
