/* Desktop */

.project {
  width: 350px;
  height: 400px;
  filter: drop-shadow(0px 6px 64px rgba(112, 144, 176, 0.1));
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  margin: 0 4rem 4rem 0;
}

.project_image {
  height: 478px;
  object-fit: cover;
}

.project .content {
  background: #fff;
  bottom: -100%;
  height: 100%;
  left: 0%;
  position: absolute;
  transition: bottom 0.4s ease-in-out;
  width: 100%;
  padding: 5rem 4rem;
}
.project:hover .content {
  bottom: 0%;
}

.card_bottom {
  position: absolute;
  bottom: 0;
  margin-bottom: 2rem;
}

/* Mobile */

.project_m {
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 24px;
  position: relative;
  overflow: hidden;
  margin: 3rem 0;
}

.project_image_m {
  height: 20rem;
  width: 100%;
  object-fit: cover;
}

.content_m {
  height: 100%;
  padding: 2rem;
  margin-top: -6px;
}
