.blogcard_root {
  position: relative;
  width: 350px;
  cursor: pointer;
  height: 400px;
  background: white;
  filter: drop-shadow(0px 6px 64px rgba(112, 144, 176, 0.1));
  box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px,
    rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
  border-radius: 24px;
  margin: 0 4rem 4rem 0;
}

.purpleBox {
  z-index: -1;
  position: absolute;
  right: 0;
  height: 250px;
  width: 200px;
  margin-top: 7rem;
  top: 0;
  background: #eff3f8;
}

.deepPurpleBox {
  z-index: 2;
  position: absolute;
  right: 0;
  height: 50px;
  width: 15px;
  margin-bottom: 8rem;
  bottom: 0;
  background: #7562df;
}

.blog_content {
  z-index: 20;
  padding: 2rem;
  margin-top: 1.5rem;
}

@media only screen and (max-width: 1199px) {
  .blogcard_root {
    margin: 0;
  }
}
