.gridFlexStart {
  display: flex;
  flex-wrap: wrap;
}

.searchBox {
  width: 40% !important;
  margin-top: 3rem;
}

@media screen and (max-width: 600px) {
  .searchBox {
    width: 100% !important;
    margin-top: 3rem;
  }
}
