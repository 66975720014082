.speaker {
  padding: 1rem 0;
}

.avatar {
  border-radius: 50px;
  height: 4rem;
  width: 4rem;
}

.speaker_content {
  margin: 0.2rem 2rem 0 0.8rem;
}

.speaker_header {
  font-size: 1.3rem;
}

.speaker_designation {
  font-size: 1rem;
  font-weight: 200;
}
