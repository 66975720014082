.container {
  position: relative;
  max-width: 90%;
}

.header {
  padding-top: 2.5rem;
}

.nav {
  height: 8rem;
}

.singleMenuItems {
  float: right;
}

.navLinks {
  color: #25282b !important;
}

.navLinksMobile {
  color: white !important;
}

.drawer {
  position: absolute;
  z-index: 20;
  top: 0;
  width: 60%;
  height: 100vh;
  padding: 5rem 0;
  color: white;
  background: rgba(0, 0, 0, 0.45);
  box-shadow: 0 8px 32px 0 rgba(156, 156, 156, 0.37);
  backdrop-filter: blur(7px);
  -webkit-backdrop-filter: blur(7px);
}

.sidebar-header {
  font-weight: 500;
  font-size: 1.5rem;
  margin-left: 1rem;
}

.sidebar-menu {
  margin-top: 2rem;
  text-decoration: none;
  list-style: none;
  padding: 0;
}

.sidebar-menu-item {
  padding: 0.7rem 0 0.7rem 1rem;
}

.sidebar-menu-item:hover {
  background: #484848;
  cursor: pointer;
}

/* Larger than mobile screen */
@media (min-width: 20rem) {
  .menuItems {
    display: none;
  }
  .menu {
    position: absolute;
    width: 3rem;
    left: 2rem;
    top: 2.8rem;
  }
  .center {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 20rem;
    margin-top: -2.5rem;
    height: auto;
  }
}

@media (min-width: 1200px) {
  .menuItems {
    width: 100%;
    float: right;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
  }
  .menu {
    display: none;
  }
  .logo {
    width: 20rem;
    margin-top: -2.5rem;
    height: auto;
  }
}

@media (min-width: 1920px) {
  .menuItems {
    width: 80%;
    float: right;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-decoration: none;
    list-style: none;
    cursor: pointer;
  }
  .menu {
    display: none;
  }
}
